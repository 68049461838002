import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { format, startOfDay } from 'date-fns';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText as InText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import { SideBar } from '../../../components/Sidebar';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import openReports from '../../../utils/openReports';
import freteOpts from '../types/freteOpts';
import { TabPanel, TabView } from 'primereact/tabview';
import { useOrder } from '../../../hooks/useOrder';
import formatCurrency from '../../../utils/numbers/FormatCurrency';
import InputRowPaymentsOrder from '../../../components/input-row/payment-order';
import { Divider } from '../../../components/Divider';
import { Tooltip } from 'primereact/tooltip';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export function OrderSidebarResume({
  isOpen,
  onRequestClose,
}: IProps): React.ReactElement {
  /** refs & focus */
  const formRef = useRef<FormHandles>(null);
  const formRefPayment = useRef<FormHandles>(null);
  const toast = useToastContext();
  const router = useHistory();

  /** states */
  const hoje = new Date();

  const [load, setLoad] = useState<boolean>(false);

  const {
    order,
    commissioneds,
    transporters,
    setOrderHeader,
    setOrderTransporter,
    setOrder,
    clearData,
  } = useOrder();

  /** effects */

  /** functions */

  /** templates */

  const [sellerNameNfe, setSellerNameNfe] = useState<boolean>(false);

  const [pay, setPay] = useState<{ value: string; rest: number }>({
    value: '',
    rest: 0,
  });

  const typeOrderOpts = [
    { label: 'Proposta', value: 'PRO' },
    { label: 'Pedido', value: 'PED' },
  ];

  useEffect(() => {
    const commissionedName = commissioneds
      ? commissioneds.find(c => c.id === order.orderHeader.commissioned_id)
          ?.name || ''
      : '';

    if (
      !(
        sellerNameNfe === true &&
        order.orderHeader.obs?.includes(` Vendedor(a): ${commissionedName}`)
      )
    ) {
      setOrderHeader({
        ...order.orderHeader,
        obs:
          sellerNameNfe === true
            ? order.orderHeader.obs?.concat(
                ` Vendedor(a): ${commissionedName}`,
              ) || ''
            : order.orderHeader.obs?.replace(
                ` Vendedor(a): ${commissionedName}`,
                '',
              ) || '',
      });
    }
  }, [sellerNameNfe]);

  const handleSubmit = async (d: any) => {
    try {
      setLoad(true);
      formRef.current?.setErrors({});

      d.delivery_date = order.orderTransporter.delivery_date;
      d.kind_freight = order.orderTransporter.kind_freight;

      const validation = Yup.object({
        kind_freight: Yup.string().required('Informe o tipo de frete!'),
        delivery_date: Yup.date()
          .min(startOfDay(hoje), 'A data limite para entrega é hoje!')
          .required('Informe a data da entrega do pedido!'),
      });
      await validation.validate(d, { abortEarly: false });

      const emitPromissory = order.orderTransporter.generate_promissory;
      delete order.orderTransporter.generate_promissory;
      const emitNfe = order.orderTransporter.emit_nfe;
      delete order.orderTransporter.emit_nfe;
      const emitOrder = order.orderTransporter.print_order;
      delete order.orderTransporter.print_order;
      delete order.orderHeader.sequence;
      delete order.orderHeader.customer;
      delete order.orderHeader.table_price_id;
      delete order.orderHeader.order_date;

      await api
        .post('/order/invoice', {
          ...order,
          orderBody: order.orderBody.map(i => {
            return {
              ...i,
              image_url: undefined,
              label: undefined,
              value: undefined,
              lucre: undefined,
              lucre_aliq: undefined,
              adic_aliq: undefined,
              desc_aliq: undefined,
              value_out: undefined,
            };
          }),
          orderTransporter: {
            ...order.orderTransporter,
            id: undefined,
            b_id: undefined,
            order_id: undefined,
            user_id: undefined,
            status: undefined,
            created_at: undefined,
            updated_at: undefined,
            transporter: undefined,
          },
          orderPayments: order.orderPayments.map(i => {
            return {
              ...i,
              description: undefined,
              paymentCondition: undefined,
              payment_condition: undefined,
              b_id: undefined,
              user_id: undefined,
              order_total: undefined,
              order_id: undefined,
              value: undefined,
              created_at: undefined,
              updated_at: undefined,
            };
          }),
        })
        .then(async ({ data }) => {
          if (data) {
            toast(
              'success',
              'Faturado',
              order.orderHeader.order_type === 'PED'
                ? 'Pedido emitido com sucesso!'
                : 'Proposta emitida com sucesso!',
            );
            if (emitOrder) {
              if (data.order.id) {
                if (order.orderHeader.order_type === 'PED') {
                  await api.post(`/reports/v2/order`, {
                    id: data.order.id,
                    promissory: emitPromissory ? '1' : '0',
                  });
                } else {
                  await api.get(`/reports/proposal/${data.order.id}`);
                }
                openReports(
                  `${
                    process.env.REACT_APP_SERVER_URL
                  }/files/pdf/${data.order.user_id.replace(/-/g, '')}.pdf`,
                  'ped_pro',
                );
              }
            }

            router.push('/orders/list');
            clearData();
          }
        })
        .catch(err => {
          toast('error', 'Erro', messageRequestError(err), 10000);
        });
    } catch (err: any) {
      if (err instanceof AxiosError) {
        if (err.response?.data?.message === 'Validation failed') {
          toast('error', 'Alerta', err.response?.data.validation.body.message);
        }
      }

      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Erro', err.errors[0]);
      } else {
        toast('error', 'Erro', err.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleChangeObs = (obs: string) => {
    setOrder({
      ...order,
      orderHeader: {
        ...order.orderHeader,
        obs,
      },
      orderTransporter: {
        ...order.orderTransporter,
        obs,
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      setSellerNameNfe(true);
    }
  }, [isOpen]);

  // w

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        className="w-11 md:w-6"
      >
        <h3>Finalização do Pedido/Proposta</h3>

        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6 px-0">
              <div className="flex align-items-center justify-content-around py-2">
                <div className="col-4 md:col-6 flex justify-content-start">
                  CLIENTE
                </div>
                <div className="col-8 md:col-6 flex justify-content-end">
                  {order.orderHeader.customer
                    ? order.orderHeader.customer.label
                    : ''}
                </div>
              </div>

              <div className="flex align-items-center justify-content-around py-2">
                <div className="col-4 md:col-6 flex justify-content-start">
                  {order.orderHeader.order_type === 'PED'
                    ? 'PEDIDO'
                    : 'PROPOSTA'}
                </div>
                <div className="col-8 md:col-6 flex justify-content-end">
                  {order.orderHeader.sequence}
                </div>
              </div>

              <div className="flex align-items-center justify-content-around py-2">
                <div className="col-4 md:col-6 flex justify-content-start">
                  DATA
                </div>
                <div className="col-8 md:col-6 flex justify-content-end">
                  {format(
                    (order.orderHeader.order_date as Date) || hoje,
                    'dd/MM/yyyy',
                  )}
                </div>
              </div>

              <div className="flex align-items-center justify-content-around py-2">
                <div className="col-4 md:col-6 flex justify-content-start">
                  TOTAL
                </div>
                <div className="col-8 md:col-6 flex justify-content-end font-bold">
                  {formatCurrency(order.orderHeader.order_total)}
                </div>
              </div>
            </div>
            <div className="field col-12 md:col-6 py-0">
              <div className="col-12 py-2">
                <SelectButton
                  value={order.orderHeader.order_type}
                  options={typeOrderOpts}
                  onChange={e => {
                    if (e.value !== null) {
                      setOrderHeader({
                        ...order.orderHeader,
                        order_type: e.value,
                      });
                    }
                  }}
                />
              </div>

              <div className="flex justify-content-between py-2">
                <span className="">Gerar promissória?</span>
                <InputSwitch
                  name="generate_promissory"
                  checked={order.orderTransporter.generate_promissory}
                  falseValue="0"
                  trueValue="1"
                  onChange={e =>
                    setOrderTransporter({
                      ...order.orderTransporter,
                      generate_promissory: e.value,
                    })
                  }
                />
              </div>

              <div className="flex justify-content-between py-2">
                <span className="emite_nfe">Gerar Nfe?</span>
                <InputSwitch
                  name="emite_nfe"
                  checked={order.orderTransporter.emit_nfe}
                  onChange={e =>
                    setOrderTransporter({
                      ...order.orderTransporter,
                      emit_nfe: e.value,
                    })
                  }
                />
              </div>

              <div className="flex justify-content-between py-2">
                <span className="">Nome do vendedor na NFe?</span>
                <InputSwitch
                  name={'seller_name_nfe'}
                  className="print_order"
                  checked={sellerNameNfe}
                  onChange={e => {
                    setSellerNameNfe(e.value);
                  }}
                />
              </div>

              <div className="flex justify-content-between py-2">
                <span className="">Imprime pedido/proposta?</span>
                <InputSwitch
                  name={'print_order'}
                  className="print_order"
                  checked={order.orderTransporter.print_order}
                  onChange={e =>
                    setOrderTransporter({
                      ...order.orderTransporter,
                      print_order: e.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <TabView>
            <TabPanel
              header="Pagamentos"
              rightIcon="ml-1 fa-solid fa-dollar-sign"
              className="pt-2"
            >
              <InputRowPaymentsOrder
                show={{
                  obs: true,
                }}
                isOpen={isOpen}
              />
            </TabPanel>
            <TabPanel
              header="Frete"
              rightIcon="ml-1 fa-solid fa-truck"
              className="pt-2"
            >
              <div className="grid">
                <div className="col-12 py-0 my-0">
                  <div className="p-fluid grid formgrid">
                    <div className="field col-4">
                      <label htmlFor="kind_freight">Tipo Frete</label>
                      <InputDropDown
                        name="kind_freight"
                        options={freteOpts}
                        value={order.orderTransporter.kind_freight}
                        placeholder="Selecionar..."
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            kind_freight: e.value,
                          })
                        }
                      />
                    </div>

                    {order.orderTransporter.kind_freight ===
                      freteOpts[0].value && (
                      <Tooltip
                        target=".no-freight-tooltip"
                        content="Selecione o tipo de frete"
                        position="bottom"
                      />
                    )}

                    <div className="field col-12 md:col-8 no-freight-tooltip">
                      <label htmlFor="transporter_id">Transportadora</label>
                      <InputDropDown
                        name="transporter_id"
                        options={
                          transporters
                            ? transporters.map(t => {
                                return {
                                  value: t.id,
                                  label: t.name,
                                };
                              })
                            : []
                        }
                        value={order.orderTransporter.transporter_id}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            transporter_id: e.value,
                          })
                        }
                        placeholder="Selecionar..."
                        filter
                        emptyFilterMessage="Nenhum item encontrado..."
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-6 md:col-4 no-freight-tooltip">
                      <label htmlFor="delivery_date">Data entrega</label>
                      <Calendar
                        name="delivery_date"
                        value={order.orderTransporter.delivery_date as Date}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            delivery_date: e.value as Date,
                          })
                        }
                        dateFormat="d/m/yy"
                        minDate={hoje}
                        showIcon
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-4 no-freight-tooltip">
                      <label htmlFor="dropdown">Veículo</label>
                      <InText
                        name="vehicle_plate"
                        placeholder="AAA-1234"
                        value={order.orderTransporter.vehicle_plate || ''}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            vehicle_plate: e.currentTarget.value,
                          })
                        }
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-8 md:col-4 no-freight-tooltip">
                      <label htmlFor="dropdown">Motorista</label>
                      <InText
                        name="driver"
                        placeholder="João do Frete"
                        value={order.orderTransporter.driver || ''}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            driver: e.currentTarget.value,
                          })
                        }
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 py-0 my-0">
                  <div className="p-fluid grid formgrid">
                    <div className="field col-4 md:col-2 no-freight-tooltip">
                      <label htmlFor="kind">Espécie</label>
                      <InputText
                        name="kind"
                        placeholder="VOL, PC, KIT..."
                        value={order.orderTransporter.kind || ''}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            kind: e.currentTarget.value,
                          })
                        }
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-4 md:col-2 no-freight-tooltip">
                      <label htmlFor="quantity">Quantidade</label>
                      <InputNumber
                        name="quantity"
                        value={order.orderTransporter.quantity}
                        onFocus={e => e.target.select()}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            quantity: e.value as number,
                          })
                        }
                        placeholder="0,00"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-4 no-freight-tooltip">
                      <label htmlFor="dropdown">N. Lacre</label>
                      <InputText
                        name="seal_number"
                        value={order.orderTransporter.seal_number || ''}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            seal_number: e.currentTarget.value,
                          })
                        }
                        placeholder="0000"
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-12 md:col-4 no-freight-tooltip">
                      <label>Marca</label>
                      <InputText
                        name="frete_marca"
                        placeholder="Própia/Terceiros"
                        value={order.orderTransporter.brand || ''}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            brand: e.currentTarget.value,
                          })
                        }
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-4 no-freight-tooltip">
                      <label htmlFor="dropdown">Peso Bruto</label>
                      <InputNumber
                        name="weight_brute"
                        value={order.orderTransporter.weight_brute}
                        placeholder="0,00"
                        onFocus={e => e.target.select()}
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            weight_brute: e.value as number,
                          })
                        }
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        suffix="kg"
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>
                    <div className="field col-4 no-freight-tooltip">
                      <label htmlFor="dropdown">Peso líquido</label>
                      <InputNumber
                        name="weight_liquid"
                        value={order.orderTransporter.weight_liquid}
                        placeholder="0,00"
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            weight_liquid: e.value as number,
                          })
                        }
                        onFocus={e => e.target.select()}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        suffix="kg"
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>

                    <div className="field col-4 no-freight-tooltip">
                      <label htmlFor="transporter_cost">Valor frete</label>
                      <InputNumber
                        name="transporter_cost"
                        value={order.orderTransporter.transporter_cost || 0}
                        placeholder="0,00"
                        onChange={e =>
                          setOrderTransporter({
                            ...order.orderTransporter,
                            transporter_cost: e.value as number,
                          })
                        }
                        onFocus={e => e.target.select()}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        disabled={order.orderTransporter.kind_freight === 'OUT'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>

          <Divider />

          <div className="col-12 py-0 my-0">
            <div className="p-fluid grid formgrid">
              <div className="field col-4">
                <label htmlFor="other_expenses">Outras despesas</label>
                <InputNumber
                  name="other_expenses"
                  value={order.orderHeader.other_expenses || 0}
                  placeholder="0,00"
                  onChange={e =>
                    setOrderHeader({
                      ...order.orderHeader,
                      other_expenses: e.value as number,
                    })
                  }
                  onFocus={e => e.target.select()}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>

              <div className="field col-8">
                <label htmlFor="doc_key">Chave Nfe</label>
                <InputText
                  name="doc_key"
                  value={order.orderHeader.doc_key || ''}
                  placeholder="00000000000000000000000000000000000000000000"
                  onChange={e =>
                    setOrderHeader({
                      ...order.orderHeader,
                      doc_key: e.currentTarget.value
                        .replaceAll(' ', '')
                        .substring(0, 44),
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Observações do pedido/proposta</label>
                <InputTextArea
                  name="obs"
                  value={order.orderHeader.obs || ''}
                  onChange={e => handleChangeObs(e.currentTarget.value)}
                  placeholder="Ex: Entregar a mercadoria na vendinha da esquina..."
                  rows={5}
                  cols={30}
                  maxLength={1024}
                  tooltip="Esse campo é usado no preenchimento das informações complementares da NF-e"
                  tooltipOptions={{ position: 'top' }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 py-0 my-0">
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-6">
                <Button
                  label="Cancelar"
                  type="button"
                  icon="pi pi-times"
                  onClick={() => onRequestClose()}
                  className="p-button-danger"
                />
              </div>

              <div className="field col-12 md:col-6">
                <Button
                  label="Faturar"
                  icon="pi pi-check"
                  className="p-button-success"
                  type="submit"
                  loading={load}
                />
              </div>
            </div>
          </div>
        </Form>
      </SideBar>
    </>
  );
}

export default OrderSidebarResume;
