import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { differenceInDays, format } from 'date-fns';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { validate } from 'uuid';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import InputRowObjectOs from '../../../components/input-row/object-os';
import InputRowProductOs from '../../../components/input-row/product-os';
import InputRowServiceOs from '../../../components/input-row/service-os';
import Progress from '../../../components/progress-bar';
import { EnumPriority } from '../../../enum/EnumPriority';
import useToastContext from '../../../hooks/toast';
import useLoadCommissioneds from '../../../hooks/useLoadCommissioneds';
import useLoadNatureOperationsShort from '../../../hooks/useLoadNatureOperationsShort';
import useLoadOsContracts from '../../../hooks/useLoadOsContracts';
import useLoadOsStatus from '../../../hooks/useLoadOsStatus';
import useLoadOsTechnicals from '../../../hooks/useLoadOsTechnicals';
import { useOrderService } from '../../../hooks/useOrderService';
import api from '../../../services/api';
import formatCurrency from '../../../utils/FormatCurrency';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import { FastFormPerson } from '../../person/fast-form-person';
import IOs from '../dtos/IOs';
import OsCheckout from '../sidebar-checkout-os/OsCheckout';
import SidebarSearchOS from '../sidebar-search-os';
import { emptyOsTransporter } from '../types/EmptyOsTransporter';
import Prioridades from '../types/Prioridades';
import TiposAtendimento from '../types/TiposAtendimento';
import { ISummary, emptySummary } from './dtos/ISummary';
import useSearchCustomer from '../../../hooks/useSearchCustomer';
import { EnumBehavior } from '../../../enum/EnumBehavior';
import { AxiosError } from 'axios';
import IOsPayment from '../dtos/IOsPayment';

const osNew: React.FC = () => {
  const today = new Date();

  const router = useHistory();
  const toast = useToastContext();
  const params: { id: string } = useParams();

  const formRef = useRef<FormHandles>(null);

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(validate(params.id));

  const [sidebarSearchOs, setSidebarSearchOs] = useState(false);
  const [sidebarPersonVisible, setSidebarPersonVisible] = useState(false);
  const [sidebarCheckoutVisible, setSidebarCheckoutVisible] = useState(false);

  const [customers, setCustomers] = useState<IOptionsDTO[]>([]);
  const [customer, setCustomer] = useState<IOptionsDTO>({
    value: '',
    label: '',
  });

  const { orderService, setData, clearData, initializeData } =
    useOrderService();
  const { data: osStatus } = useLoadOsStatus();
  const { data: natureOperations } = useLoadNatureOperationsShort({
    paginate: { page: 1, perPage: 100, kind: ['saida'], status: ['A'] },
  });
  const { data: osTechnical } = useLoadOsTechnicals();
  const { data: commissioneds } = useLoadCommissioneds();
  const { data: osContracts } = useLoadOsContracts();

  const [summary, setSummary] = useState<ISummary>(emptySummary);

  const loadOs = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/os/${id}`)
      .then(({ data }) => {
        if (data) {
          const editOs: IOs = {
            isEdit: true,
            osHeader: {
              id: data.id,
              b_id: data.b_id,
              os_number: data.os_number,
              nat_ope_id: data.nat_ope_id,
              customer_id: data.customer_id,
              customer: {
                id: data.customer.id,
                name: data.customer.name,
              },
              description: data.description,
              kind_atendimento: data.kind_atendimento,
              date_open: new Date(data.date_open),
              date_close:
                data.date_close !== null ? new Date(data.date_close) : today,
              date_prevision: new Date(data.date_prevision),
              contract_id: data.contract_id,
              technical_id: data.technical_id,
              commissioned_id: data.commissioned_id || '',
              priority: data.priority,
              os_status_id: data.os_status_id,
              total_objects: data.total_objects,
              total_services: data.total_products,
              total_products: data.total_products,
              total_payments: data.osPayments.reduce((a: number, i: any) => {
                a += Number(i.value_parcel);
                return a;
              }, 0),
              total_desc: data.total_desc,
              perc_desc: data.perc_desc,
              total_adic: data.total_adic,
              perc_adic: data.perc_adic,
              juros: data.juros,
              total: data.total,
              obs_public: data.obs_public,
              obs_private: data.obs_private,
              user_id: data.user_id,
              status: data.status,
              close: data.close,
              os_registered: data.os_registered,
            },
            osObjects: data.osObjects.map((i: any) => {
              return { ...i, object_title: i.object.title || '' };
            }),
            osServices: data.osServices.map((i: any) => {
              return {
                ...i,
                service_title: i.service.title,
                technical_title: i.technical?.name || null,
              };
            }),
            osProducts: data.osProducts.map((i: any) => {
              return {
                ...i,
                product_description: i.product.title || '',
                table_price_description: i.tablePrice.title || '',
              };
            }),
            osPayments: data.osPayments
              .sort(
                (a: IOsPayment, b: IOsPayment) =>
                  Number(a.parcel) - Number(b.parcel),
              )
              .sort((a: IOsPayment, b: IOsPayment) =>
                a.group_id.localeCompare(b.group_id),
              )
              .map((i: any) => {
                return {
                  ...i,
                  description: `${i.payment.title} - ${i.parcel}/${i.payment.qnt_parcelas}`,
                };
              }),
            osStatus: data.osStatus,
            osTransporter: data.osTransporter
              ? {
                  tracking_code: data.osTransporter.tracking_code,
                  post_date:
                    data.osTransporter.post_date !== null
                      ? new Date(data.osTransporter.post_date)
                      : today,
                  delivery_date:
                    data.osTransporter.delivery_date !== null
                      ? new Date(data.osTransporter.delivery_date)
                      : today,
                  transporter_id: data.osTransporter.transporter_id,
                  vehicle_plate: data.osTransporter.vehicle_plate,
                  driver: data.osTransporter.driver,
                  kind_freight: data.osTransporter.kind_freight,
                  quantity: data.osTransporter.quantity,
                  kind: data.osTransporter.kind,
                  brand: data.osTransporter.brand,
                  seal_number: data.osTransporter.seal_number,
                  weight_brute: data.osTransporter.weight_brute,
                  weight_liquid: data.osTransporter.weight_liquid,
                  cubage: data.osTransporter.cubage,
                  cost: data.osTransporter.cost,
                  obs: data.osTransporter.obs,
                }
              : emptyOsTransporter,
          };
          if (editOs.osHeader.customer) {
            setCustomer({
              value: editOs.osHeader.customer_id || '',
              label: editOs.osHeader.customer.name || '',
            });
          }

          setData({ ...editOs });
        }
      })
      .catch((err: AxiosError) =>
        toast('error', 'Erro', messageRequestError(err)),
      )
      .finally(() => setIsLoad(false));
  };

  const handleClearData = () => {
    clearData();
    setCustomer({ value: '', label: '' });
  };

  const handleSendOsWhatsapp = async (id: string) => {};

  const handleSendOsMail = async (id: string) => {};

  const handleCheckout = async (d: any) => {
    if (orderService.osServices.length === 0) {
      toast('warn', 'Alerta', 'Informe pelo menos 1 serviço na O.S!');
      return;
    }

    try {
      formRef.current?.setErrors({});

      d.commissioned_id = orderService.osHeader.commissioned_id;
      d.contract_id = orderService.osHeader.contract_id;
      d.nat_ope_id = orderService.osHeader.nat_ope_id;
      d.customer_id = orderService.osHeader.customer_id;
      d.date_open = orderService.osHeader.date_open;
      d.date_prevision = orderService.osHeader.date_prevision;
      d.date_close = orderService.osHeader.date_close;
      d.os_status = orderService.osHeader.os_status_id;
      d.technical_id = orderService.osHeader.technical_id;

      const validation = Yup.object({
        commissioned_id: Yup.string().default(''),
        contract_id: Yup.string()
          .required('Informe o contrato de garantia dos serviços!')
          .default(''),
        nat_ope_id: Yup.string()
          .uuid('Informe um id valido para natureza de operação!')
          .required('Informe uma natureza de operarção!'),
        customer_id: Yup.string()
          .uuid('Informe um cliente válido!')
          .required('Informe o cliente!'),
        date_close: Yup.date(),
        date_open: Yup.date(),
        date_prevision: Yup.date(),
        kind_atendimento: Yup.string(),
        obs_private: Yup.string(),
        obs_public: Yup.string(),
        order_num: Yup.number(),
        os_status: Yup.string()
          .uuid('Informe um status válido!')
          .required('Informe um status para a O.S'),
        priority: Yup.string(),
        technical_id: Yup.string()
          .uuid('Informe um técnico válido!')
          .required('Informe um técnico!'),
      });
      await validation.validate(d, { abortEarly: false });

      setSidebarCheckoutVisible(true);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    }
  };

  const searchCustomers = async (search: string) => {
    setIsLoad(true);

    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setCustomers(
          customers.map((item: any) => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        );
      }
    } catch (err: any) {
      toast('error', 'Error', err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const loadDefaultData = async () => {
    setIsLoad(true);

    await api
      .get('/params/os/default')
      .then(({ data }) => {
        setData({
          ...orderService,
          osHeader: {
            ...orderService.osHeader,
            nat_ope_id: data.defaultOSParams.nat_ope_id || '',
            os_status_id: data.defaultOSParams.status_id || '',
            technical_id: data.defaultOSParams.technical_id || '',
            commissioned_id: data.defaultOSParams.commissioned_id || '',
            contract_id: data.defaultOSParams.contract_id || '',
            priority: EnumPriority.BAIXA,
          },
        });
      })
      .catch((e: any) => {
        toast(
          'warn',
          'Falha',
          `Falha ao carregar os parâmetros de ordem de serviços: ${messageRequestError(
            e,
          )}`,
        );
      })
      .finally(() => setIsLoad(false));
  };

  useEffect(() => {
    if (validate(params.id)) {
      loadOs(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (!validate(params.id)) {
      loadDefaultData();
      initializeData();
    }
  }, []);

  return (
    <>
      <>
        <Button
          label="Novo"
          className="mb-3"
          icon="fa-solid fa-plus"
          onClick={() => router.push('/os/new')}
        />
        <Button
          label="Buscar"
          className="mb-3 mx-2"
          icon="fa-solid fa-search"
          onClick={() => setSidebarSearchOs(true)}
        />
        <Button
          label="Listar"
          className="mb-3 mx-2"
          icon="fa-solid fa-list"
          onClick={() => {
            router.push('/os/list');
            clearData();
          }}
        />
        <Button
          label="Whatsapp"
          className="mb-3 mx-2 p-button-success"
          icon="fa-brands fa-whatsapp"
          onClick={async () => handleSendOsWhatsapp(params.id)}
          disabled={!validate(params.id)}
        />
        <Button
          label="Email"
          className="mb-3 mx-2"
          icon="fa-solid fa-envelope"
          onClick={async () => handleSendOsMail(params.id)}
          disabled={!validate(params.id)}
        />
      </>

      <Progress isLoad={isLoad} />

      <div className="card">
        <Form
          ref={formRef}
          onSubmit={d => handleCheckout(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className="field col-12 md:col-4">
            <label htmlFor="dropdown">
              Cliente
              <i
                className={`pi ${
                  validate(customer.value)
                    ? 'pi-search text-blue-500'
                    : 'pi-plus-circle text-green-500'
                } ml-2 cursor-pointer icon-sidebar-person`}
                onClick={() => setSidebarPersonVisible(true)}
              ></i>
            </label>
            <InputAutoComplete
              name="customer_id"
              suggestions={customers}
              value={customer}
              completeMethod={e => searchCustomers(e.query)}
              field="label"
              onChange={e => {
                setCustomer(e.value);
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    customer_id: e.value.value,
                    customer: {
                      id: e.value.value,
                      name: e.value.label,
                    },
                  },
                });
              }}
              itemTemplate={itemTemplate}
              placeholder="Buscar cliente..."
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="nat_ope_id">Natureza operação</label>
            <InputDropDown
              name="nat_ope_id"
              options={natureOperations
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map(i => {
                  return {
                    value: i.id,
                    label: i.title,
                  };
                })}
              value={orderService.osHeader?.nat_ope_id}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    nat_ope_id: e.value,
                    natOpe: {
                      title:
                        natureOperations?.find(i => i.id === e.value)?.title ||
                        '',
                    },
                  },
                })
              }
              placeholder="Selecionar..."
              filter
            />
          </div>
          <div className="field col-12 md:col-2">
            <label htmlFor="kind_atendimento">Atendimento</label>
            <InputDropDown
              name="kind_atendimento"
              options={TiposAtendimento}
              value={orderService.osHeader?.kind_atendimento}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    kind_atendimento: e.value,
                  },
                })
              }
              placeholder="Selecionar..."
              filter
            />
          </div>
          <div className="field col-12 md:col-2">
            <label htmlFor="os_status">Status</label>
            <InputDropDown
              name="os_status"
              options={osStatus
                ?.sort((a, b) => a.position - b.position)
                .map(i => {
                  return {
                    value: i.id,
                    label: `${i.position} - ${i.title}`,
                  };
                })}
              value={orderService.osHeader.os_status_id}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    os_status_id: e.value,
                    osStatus: {
                      title: osStatus?.find(i => i.id === e.value)?.title || '',
                      is_last:
                        osStatus?.find(i => i.id === e.value)?.is_last || false,
                    },
                  },
                })
              }
              placeholder="Selecionar..."
              filter
            />
          </div>
          <div className="field col-12 md:col-1">
            <label htmlFor="order_num">Número</label>
            <InputText
              name="order_num"
              value={orderService.osHeader?.os_number}
              disabled
              className="surface-200"
              style={{ textAlign: 'center' }}
            />
          </div>

          <Divider align="left" type="dashed" className="mt-0">
            <div className="inline-flex align-items-center">
              <span className="p-tag">
                <i className="fa-solid fa-calendar-days mx-2"></i>
                Datas
              </span>
            </div>
          </Divider>
          <div className="field col-12 md:col-4">
            <label htmlFor="date_open">Abertura O.S</label>
            <Calendar
              name="date_open"
              value={orderService.osHeader?.date_open}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    date_open: e.value as Date,
                  },
                })
              }
              dateFormat="d/m/yy"
              showTime
              showIcon
              disabled={isEdit}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="date_prevision">Previsão O.S</label>
            <Calendar
              name="date_prevision"
              value={orderService.osHeader?.date_prevision}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    date_prevision: e.value as Date,
                  },
                })
              }
              dateFormat="d/m/yy"
              showTime
              showIcon
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="date_close">Fechamento O.S</label>
            <Calendar
              name="date_close"
              value={
                orderService.osHeader.date_close
                  ? orderService.osHeader.date_close
                  : today
              }
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    date_close: e.value as Date,
                  },
                })
              }
              placeholder="00/00/00"
              dateFormat="d/m/yy"
              minDate={today}
              showIcon
              disabled={isEdit ? false : true}
              className={isEdit ? 'surface-200' : ''}
            />
          </div>
          <Divider align="left" type="dashed" className="mt-0">
            <div className="inline-flex align-items-center">
              <span className="p-tag">
                <i className="fa-solid fa-users mx-2"></i>
                Equipe
              </span>
            </div>
          </Divider>
          <div className="field col-12 md:col-3">
            <label htmlFor="technical_id">Técnico/Profissional</label>
            <InputDropDown
              name="technical_id"
              options={osTechnical
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(i => {
                  return { value: i.id, label: i.name };
                })}
              value={orderService.osHeader?.technical_id}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    technical_id: e.value,
                    technical: {
                      name:
                        osTechnical?.find(i => i.id === e.value)?.name || '',
                    },
                  },
                })
              }
              placeholder="Selecionar..."
              filter
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="commissioned_id">Comissionado</label>
            <InputDropDown
              name="commissioned_id"
              options={commissioneds
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(i => {
                  return {
                    value: i.id,
                    label: i.name,
                  };
                })}
              value={orderService.osHeader?.commissioned_id}
              onChange={e => {
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    commissioned_id: e.value,
                    commissioned: {
                      name: e.value.label,
                    },
                  },
                });
              }}
              placeholder="Selecionar..."
              filter
            />
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="priority">
              Prioridade
              <Tag
                className="mx-2"
                icon="pi pi-exclamation-triangle"
                severity={
                  orderService.osHeader?.priority === EnumPriority.BAIXA
                    ? 'success'
                    : orderService.osHeader?.priority === EnumPriority.MEDIA
                    ? 'warning'
                    : orderService.osHeader?.priority === EnumPriority.ALTA
                    ? 'danger'
                    : 'info'
                }
              ></Tag>
            </label>
            <InputDropDown
              name="priority"
              options={Prioridades}
              value={orderService.osHeader?.priority}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: { ...orderService.osHeader, priority: e.value },
                })
              }
              placeholder="Selecionar..."
              filter
            />
          </div>

          <div className="field col-12 md:col-3">
            <label htmlFor="contract_id">Contrato/Garantia</label>
            <InputDropDown
              name="contract_id"
              options={osContracts
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map(i => {
                  return {
                    value: i.id,
                    label: i.title,
                  };
                })}
              value={orderService.osHeader.contract_id}
              onChange={e => {
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    contract_id: e.value,
                    contract: {
                      title:
                        osContracts?.find(i => i.id === e.value)?.title || '',
                    },
                  },
                });
              }}
              placeholder="Selecionar..."
              filter
            />
          </div>

          <Divider align="left" type="dashed" className="mt-0">
            <div className="inline-flex align-items-center">
              <span className="p-tag">
                <i className="fa-solid fa-clipboard-list mx-2"></i>
                Observações
              </span>
            </div>
          </Divider>
          <div className="field col-12 md:col-6">
            <label htmlFor="obs_public">Laudo/Observações públicas</label>
            <InputTextArea
              name="obs_public"
              value={orderService.osHeader?.obs_public}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    obs_public: e.currentTarget.value,
                  },
                })
              }
              placeholder="Ex: O aparelho apresenta problema no componente A, B, C..."
              rows={5}
              cols={30}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="obs_private">
              Observações
              <Tag
                severity="warning"
                value="privadas"
                rounded
                className="mx-2"
              ></Tag>
            </label>
            <InputTextArea
              name="obs_private"
              value={orderService.osHeader?.obs_private}
              onChange={e =>
                setData({
                  ...orderService,
                  osHeader: {
                    ...orderService.osHeader,
                    obs_private: e.currentTarget.value,
                  },
                })
              }
              placeholder="Ex: O aparelho apresenta problema no componente A, B, C..."
              rows={5}
              cols={30}
              tooltip="Estas observações não são mostradas para o cliente no link de acompanhamento!"
              tooltipOptions={{ position: 'bottom' }}
            />
          </div>
        </Form>

        <div className="p-fluid grid formgrid">
          <Accordion
            multiple
            activeIndex={[]}
            className="field col-12 md:col-12"
          >
            <AccordionTab
              header={
                <div className="flex align-items-center">
                  <i className="fa-solid fa-mobile-screen mx-2"></i>
                  <span className="vertical-align-middle">
                    Objeto/Equipamento - {orderService.osHeader.total_objects}
                  </span>
                </div>
              }
            >
              <InputRowObjectOs />
            </AccordionTab>
            <AccordionTab
              header={
                <div className="flex align-items-center">
                  <i className="fa-solid fa-wrench mx-2"></i>
                  <span className="vertical-align-middle">
                    Serviços{' - '}
                    {formatCurrency(orderService.osHeader.total_services || 0)}
                  </span>
                </div>
              }
            >
              <InputRowServiceOs />
            </AccordionTab>
            <AccordionTab
              header={
                <div className="flex align-items-center">
                  <i className="fa-solid fa-box mx-2"></i>
                  <span className="vertical-align-middle">
                    Produtos{' - '}
                    {formatCurrency(orderService.osHeader.total_products || 0)}
                  </span>
                </div>
              }
            >
              <InputRowProductOs
                show={{
                  title: false,
                  obs: true,
                }}
                setup={{
                  isKeyboard: false,
                }}
              />
            </AccordionTab>
          </Accordion>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Divider align="left" type="dashed" className="mt-0 mb-2">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="fa-solid fa-book mx-2"></i>
                  RESUMO
                </span>
              </div>
            </Divider>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-4 text-left">Objetos</div>
              <div className="col-6 md:col-4 text-center">
                Qnt:{' '}
                {formatDecimal(
                  orderService.osObjects.reduce((a, i) => {
                    a += Number(i.qnt);
                    return a;
                  }, 0),
                )}
              </div>
              <div className="col-6 md:col-4 text-right">
                Total:{' '}
                {formatCurrency(orderService.osHeader.total_objects || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-4 text-left">Serviços</div>
              <div className="col-6 md:col-4 text-center">
                Qnt:{' '}
                {formatDecimal(
                  orderService.osServices.reduce((a, i) => {
                    a += Number(i.qnt);
                    return a;
                  }, 0),
                )}
              </div>
              <div className="col-6 md:col-4 text-right">
                Total:{' '}
                {formatCurrency(orderService.osHeader.total_services || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-4 text-left">Produtos</div>
              <div className="col-6 md:col-4 text-center">
                Qnt:{' '}
                {formatDecimal(
                  orderService.osProducts.reduce((a, i) => {
                    a += Number(i.qnt);
                    return a;
                  }, 0),
                )}
              </div>
              <div className="col-6 md:col-4 text-right">
                Total:{' '}
                {formatCurrency(orderService.osHeader.total_products || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-4">Período na loja</div>
              <div className="col-6 md:col-4">
                {format(orderService.osHeader.date_open || today, 'd/M/yy H:m')}
                {' # '}
                {format(today, 'd/M/yy H:m')}
              </div>
              <div className="col-6 md:col-4 text-right">
                {differenceInDays(
                  today,
                  orderService.osHeader.date_open || today,
                ) !== 0
                  ? `${differenceInDays(
                      today,
                      orderService.osHeader.date_open || today,
                    )} dia(s)`
                  : 'Desde hoje'}
              </div>
            </div>
          </div>
          <div className="field col-12 md:col-6">
            <Divider align="left" type="dashed" className="mt-0 mb-2">
              <div className="inline-flex align-items-center">
                <span className="p-tag">
                  <i className="fa-solid fa-dollar mx-2"></i>
                  TOTAIS
                </span>
              </div>
            </Divider>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">BRUTO</div>
              <div className="col-6 md:col-6 text-right">
                {formatCurrency(summary.totals.bruto)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">DESCONTOS</div>
              <div className="col-6 md:col-6 text-right">
                {formatCurrency(orderService.osHeader.total_desc || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">ADICIONAIS</div>
              <div className="col-6 md:col-6 text-right">
                {formatCurrency(orderService.osHeader.total_adic || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">
                JUROS
                <Tag severity="info" value={`${0}%`} className="ml-3"></Tag>
              </div>
              <div className="col-6 md:col-6 text-right">
                {formatCurrency(orderService.osHeader.juros || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">ACERTOS</div>
              <div className="col-6 md:col-6 text-right">
                {formatCurrency(orderService.osHeader.total_payments || 0)}
              </div>
            </div>

            <div className="flex align-items-center justify-content-around mb-3">
              <div className="col-6 md:col-6">
                <strong>TOTAL</strong>
              </div>
              <div className="col-6 md:col-6 text-right">
                <strong>
                  {formatCurrency(orderService.osHeader.total || 0)}
                </strong>
              </div>
            </div>
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
              onClick={() => handleClearData()}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label={isEdit ? 'Editar' : 'Faturar'}
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
              onClick={() => formRef.current?.submitForm()}
            />
          </div>
        </div>
      </div>

      <FastFormPerson
        isOpen={sidebarPersonVisible}
        onRequestClose={() => setSidebarPersonVisible(false)}
        behavior={
          validate(customer.value) ? EnumBehavior.UPDATE : EnumBehavior.CREATE
        }
        personId={customer.value}
        personType="C"
      />

      <SidebarSearchOS
        isOpen={sidebarSearchOs}
        onRequestClose={() => setSidebarSearchOs(false)}
        data={{} as any}
      />

      <OsCheckout
        isOpen={sidebarCheckoutVisible}
        onRequestClose={() => setSidebarCheckoutVisible(false)}
      />
    </>
  );
};

export default osNew;
