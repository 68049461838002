import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError, AxiosResponse } from 'axios';
import { cnpj } from 'cpf-cnpj-validator';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputMask } from '../../../components/Inputs/InputMask';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { SideBar } from '../../../components/Sidebar';
import Progress from '../../../components/progress-bar';
import useToastContext from '../../../hooks/toast';
import api, { apiCep, apiTS } from '../../../services/api';
import LimpaCpfCnpj from '../../../utils/LimpaCpfCnpj';
import getValidationErrors from '../../../utils/getErrorsValidation';
import { emptyPersonUpdate, ICity, IPersonUpdate } from '../dtos/IPerson';
import messageRequestError from '../../../utils/messageRequestError';
import { EnumBehavior } from '../../../enum/EnumBehavior';
import { EnumTypePerson } from '../../../enum/EnumTypePerson';
import TipoPessoaOpts from '../types/TipoPessoaOpts';
import CivilStatusOpts from '../types/CivilStatusOpts';
import GeneroOpts from '../types/GeneroOpts';
import ContribuinteOpts from '../types/ContribuinteOpts';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import RegimeTributarioOpts from '../types/RegimeTributarioOpts';
import UfsOpts from '../types/UfsOpts';
import { EnumStatus } from '../../../enum/EnumStatus';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import { convertStringToDate2 } from '../../../utils/date/ConvertStringToDate2';
import stringIsNotNull from '../../../utils/strings/stringIsNotNull';
import formatPhoneNumbers from '../../../utils/strings/formatPhoneNumbers';

interface FastFormProps {
  isOpen: boolean;
  behavior: EnumBehavior;
  personId: string;
  personType: string;
  onRequestClose: () => void;
}

export function FastFormPerson({
  isOpen,
  behavior,
  personId,
  personType,
  onRequestClose,
}: FastFormProps) {
  /** refs & focus */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** states */
  const today = new Date();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [data, setData] = useState<IPersonUpdate>(emptyPersonUpdate);

  const [cities, setCities] = useState<ICity[]>([]);

  /** effects */
  useEffect(() => {
    loadCities();
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (behavior === EnumBehavior.UPDATE) {
        loadPerson(personId);
      } else {
        clearData();
      }
    } else {
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      behavior === EnumBehavior.CREATE &&
      LimpaCpfCnpj(data.address.post_code).length === 8
    ) {
      loadAddress(data.address.post_code);
    }
  }, [data.address.post_code]);

  /** functions */

  const loadCities = async () => {
    setIsLoad(true);
    await api
      .post(`/cities/list`, {
        pagination: { page: 1, perPage: 10000, status: ['A'] },
      })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCities(
            res.data.map((i: any) => {
              return {
                id: i.id,
                value: i.id,
                ibge: i.ibge,
                siafi: i.siafi,
                label: i.name,
                uf: i.uf.sigla,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadPerson = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/persons/${id}`)
      .then(res => {
        if (res) {
          if (res.data.codigo !== 1) {
            toast('info', 'Dados', 'Dados carregados com sucesso!');

            /** Removendo campos que não serão usados e
             * geram erro no handleSubmit */
            delete res.data.b_id;
            delete res.data.user_id;
            delete res.data.created_at;
            delete res.data.updated_at;
            delete res.data.status;

            const addresses = res.data.addresses;
            delete res.data.addresses;
            delete addresses[0].id;
            delete addresses[0].b_id;
            delete addresses[0].person_id;
            delete addresses[0].status;
            delete addresses[0].created_at;
            delete addresses[0].updated_at;

            const contacts = res.data.contacts;
            delete res.data.contacts;
            delete contacts[0].id;
            delete contacts[0].b_id;
            delete contacts[0].person_id;
            delete contacts[0].status;
            delete contacts[0].created_at;
            delete contacts[0].updated_at;

            setData({
              person: {
                ...res.data,
                sex: res.data.sex !== null ? res.data.sex : 'O',
                contribuinte: Number(res.data.contribuinte),
                birthday:
                  res.data.birthday !== null
                    ? convertStringToDate2(res.data.birthday)
                    : today,
              },
              address:
                addresses && addresses.length > 0
                  ? {
                      ...addresses[0],
                      city: {
                        name: addresses[0].city.name,
                        uf: addresses[0].city.uf.sigla,
                      },
                    }
                  : data.address,
              contact:
                contacts && contacts.length > 0
                  ? { ...contacts[0] }
                  : data.contact,
            });
          } else {
            toast(
              'info',
              'Alerta',
              'Você não pode alterar os dados do CONSUMIDOR padrão.',
              8000,
            );
            onRequestClose();
          }
        }
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao carregar os dados da entidade: ${e.message}`,
          8000,
        );
        return;
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadAddress = async (cep: string) => {
    if (!cep || cep.length < 8) {
      toast('warn', 'Alerta', 'Informe um CEP válido!');
      return;
    }

    setIsLoad(true);

    const clearCep = LimpaCpfCnpj(cep);

    await apiCep
      .get(`/${clearCep}/json/`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          const city = cities.find(i => i.ibge === res.data.ibge);

          setData({
            ...data,
            address: {
              ...data.address,
              post_code: cep,
              street: stringIsNotNull(res.data.logradouro)
                ? res.data.logradouro
                : data.address.street,
              complement: stringIsNotNull(res.data.complemento)
                ? res.data.complemento
                : data.address.complement,
              neighborhood: stringIsNotNull(res.data.bairro)
                ? res.data.bairro
                : data.address.neighborhood,
              city_id: city ? city.id : data.address.city_id,
              city: city ? city : data.address.city,
            },
          });

          toast(
            'info',
            'Endereço',
            'Dados de endereço encontrados com sucesso!',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleClearForm = () => {
    setData(emptyPersonUpdate);
  };

  const handleSearchCnpj = async (doc: string) => {
    setIsLoad(true);
    if (!cnpj.isValid(doc)) {
      toast(
        'warn',
        'Alerta',
        `O CNPJ informado: ${cnpj.format(doc)} não é válido!`,
      );
      return;
    }

    const headers = { 'x-api-key': `${process.env.REACT_APP_TS_TOKEN_NEXT}` };

    await apiTS
      .get(`/cnpj/${LimpaCpfCnpj(doc)}`, { headers })
      .then((res: AxiosResponse) => {
        if (res.data) {
          if (res.data.status && res.data.status === 'ERROR') {
            toast('warn', 'Alerta', res.data.message);
            return;
          }
          if (res.data.situacao === 'BAIXADA')
            toast(
              'warn',
              'Alerta',
              `A empresa portadora do CNPJ ${doc} está baixada!`,
              6000,
            );

          const _obs = `Sócio: ${
            res.data.socios && res.data.socios.length > 0
              ? res.data.socios.map((i: any) => {
                  return `\n${i.nome} ~ ${i.qualificacao}`;
                })
              : ''
          }, \nData abertura: ${
            res.data.data_abertura
          }, Capital sócial: ${formatDecimal(
            res.data.capital_social,
          )}, \nAtividades: ${
            res.data.atividades && res.data.atividades.length > 0
              ? res.data.atividades.map((i: any) => {
                  return `\n${i.codigo} - ${i.descricao}`;
                })
              : ''
          }`;

          setData({
            ...data,
            person: {
              ...data.person,
              name: res.data.razao_social,
              nickname: res.data.fantasia,
              type: 'J',
              cpf_cnpj: res.data.cpf_cnpj,
              rg_ie: '',
              insc_municipal: '',
              insc_suframa: '',
              contribuinte: '0',
              regime_tributario: 'N',
              customer: 'S',
              provider: 'N',
              transporter: 'N',
              birthday: new Date(),
              block: 'N',
              civil_status: 'S',
              sex: 'O',
              obs: _obs.length < 1024 ? _obs : `${_obs.substring(0, 1020)}...`,
            },
            address: {
              ...data.address,
              post_code: res.data.endereco.cep,
              street: stringIsNotNull(res.data.endereco.logradouro)
                ? res.data.endereco.logradouro
                : data.address.street,
              neighborhood: stringIsNotNull(res.data.endereco.bairro)
                ? res.data.endereco.bairro
                : data.address.neighborhood,
              complement: stringIsNotNull(res.data.complemento)
                ? res.data.complemento
                : data.address.complement,
              number: stringIsNotNull(res.data.endereco.numero)
                ? res.data.endereco.numero
                : data.address.number,
            },
            contact: {
              ...data.contact,
              phone_whatsapp: stringIsNotNull(res.data.telefone)
                ? formatPhoneNumbers(res.data.telefone).mobile
                : data.contact.phone_whatsapp,
              phone_fixed: stringIsNotNull(res.data.telefone)
                ? formatPhoneNumbers(res.data.telefone).fixed
                : data.contact.phone_fixed,
              email: res.data.email,
            },
          });

          toast('info', 'Dados', 'Dados carregados na receita com sucesso!');
        }
      })
      .catch((e: AxiosError) => {
        toast(
          'warn',
          'Falha',
          `Falha ao buscar os dados da receita, preencha manualmente ou tente novamente mais tarde: ${e.message}`,
        );
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleChangeUf = (uf: string) => {};

  const clearData = async () => {
    setIsLoad(true);
    await api
      .get('/persons/last/codigo')
      .then((res: AxiosResponse) => {
        if (res.data) {
          setData({
            ...emptyPersonUpdate,
            person: {
              ...data.person,
              codigo: res.data + 1,
            },
          });
        }
      })
      .catch((err: AxiosError) =>
        toast('warn', 'Alerta', messageRequestError(err)),
      )
      .finally(() => setIsLoad(false));
  };

  const handleChangePersonStatus = async (isBlock: string) => {
    setIsLoad(true);

    await api
      .put(
        `/persons/${isBlock === 'S' ? 'disable' : 'enable'}/${data.person.id}`,
      )
      .then(({ data: res }) => {
        if (res) {
          toast(
            'success',
            'Sucesso',
            `${
              personType === 'C'
                ? 'Cliente'
                : personType === 'P'
                ? 'Fornecedor'
                : 'Transportadora'
            } ${isBlock === 'S' ? 'bloqueado' : 'desbloqueado'} com sucesso!`,
          );

          setData({ ...data, person: { ...data.person, block: isBlock } });
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const handleSubmit = async () => {
    if (
      data.contact.phone_cel === '' &&
      data.contact.phone_fixed === '' &&
      data.contact.phone_mobile === '' &&
      data.contact.phone_whatsapp === ''
    ) {
      toast(
        'error',
        'Error!',
        'Por favor, informe pelo menos um número de telefone!',
      );
      return;
    }

    try {
      formRef.current?.setErrors({});

      const personSchema = Yup.object({
        codigo: Yup.number().required('Informe um código válido!'),
        type: Yup.string().required('Informe se é pessoa física ou jurídica!'),
        customer: Yup.string().required('Informe se é Cliente Sim/Não.'),
        provider: Yup.string().required('Informe se é Fornecedor Sim/Não.'),
        transporter: Yup.string().required(
          'Informe se é Transportadora Sim/Não.',
        ),
        name: Yup.string()
          .min(3, 'Nome/Razão social deve ter no mínimo 3 caracteres.')
          .max(64, 'Nome/Razão social deve ter no máximo 64 caracteres.')
          .required('Informe o Nome/Razão Social'),
        nickname: Yup.string()
          .min(3, 'Apelido/Nome Fantasia deve ter no mínimo 3 caracteres.')
          .max(64, 'Apelido/Nome Fantasia deve ter no máximo 64 caracteres.'),
        cpf_cnpj: Yup.string().required('Informe um CPF/CNPJ.'),
        rg_ie: Yup.string(),
        sex: Yup.string().when('type', {
          is: 'F',
          then: Yup.string().required('Por favor, informe seu sexo!'),
        }),
        birthday: Yup.date().when('type', {
          is: 'F',
          then: Yup.date().typeError('Por favor, Informe uma data valida!'),
        }),
        civil_status: Yup.string().when('type', {
          is: 'F',
          then: Yup.string().required('Por favor, informe seu status civil!'),
        }),
        block: Yup.string(),
        regime_tributario: Yup.string(),
        insc_suframa: Yup.string(),
        insc_municipal: Yup.string(),
        contribuinte: Yup.string().required(
          'Pro favor, informe o contribuinte!',
        ),
        obs: Yup.string().max(
          1024,
          'Observações adicionais deve ter no máximo 1024 caracteres!',
        ),
      });
      await personSchema.validate(data.person, { abortEarly: false });

      const addressSchema = Yup.object({
        post_code: Yup.string().required('Por favor, informe o cep!'),
        street: Yup.string()
          .max(64, 'Rua pode ter no máximo 64 caracteres!')
          .required('Por favor, informe a rua!'),
        number: Yup.string()
          .max(9, 'Número pode ter no máximo 9 caracteres')
          .required('Por favor, informe o numero!'),
        neighborhood: Yup.string()
          .max(64, 'Bairro pode ter no máximo 32 caracteres.')
          .required('Por favor, informe o bairro'),
        complement: Yup.string().max(
          64,
          'Complemento pode ter no máximo 64 caracteres',
        ),
        city_id: Yup.string().uuid().required('Por favor, informe a cidade!'),
        city: Yup.object({
          uf: Yup.string(),
        }),
      });
      await addressSchema.validate(data.address, { abortEarly: false });

      const contactSchema = Yup.object({
        phone_cel: Yup.string(),
        phone_fixed: Yup.string(),
        phone_mobile: Yup.string(),
        phone_whatsapp: Yup.string(),
        email: Yup.string().email(),
        site: Yup.string().url(),
        facebook: Yup.string().url(),
        instagram: Yup.string().url(),
        youtube: Yup.string().url(),
        twitter: Yup.string().url(),
      });
      await contactSchema.validate(data.contact, { abortEarly: false });

      delete data.address.city;

      const res =
        behavior === EnumBehavior.CREATE
          ? await api.post('/persons', { ...data })
          : await api.put('/persons/', { ...data });

      if (res.status >= 200 && res.status <= 299) {
        toast('success', 'Sucesso!', 'Salvo com sucesso!');
      }

      clearData();

      onRequestClose();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    }
  };

  return (
    <SideBar
      visible={isOpen}
      position="right"
      onHide={() => {
        onRequestClose();
      }}
      className="w-10 md:w-6"
      style={{ width: '60vw' }}
    >
      <h2>
        {behavior === EnumBehavior.CREATE
          ? `${personType === 'T' ? 'Nova ' : 'Novo '}`
          : 'Editar '}
        {personType === 'C'
          ? 'cliente'
          : personType === 'P'
          ? 'fornecedor'
          : 'transportadora'}
      </h2>

      <Progress isLoad={isLoad} />

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
        className="p-fluid grid formgrid"
      >
        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">Dados</span>
          </div>
        </Divider>
        <div className="field col-12 md:col-5">
          <label htmlFor="name">Nome/Razão Social</label>
          <InputText
            name="name"
            placeholder="Ex.: João / Acme Industria"
            value={data.person.name}
            onChange={e => {
              formRef.current?.setFieldError('name', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  name: e.currentTarget.value,
                },
              });
            }}
          />
        </div>
        <div className="field col-12 md:col-5">
          <label htmlFor="nickname">Apelido/Nome Fantasia</label>
          <InputText
            name="nickname"
            placeholder="Ex.: João Acme"
            value={data.person.nickname}
            onChange={e => {
              formRef.current?.setFieldError('nickname', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  nickname: e.currentTarget.value,
                },
              });
            }}
          />
        </div>
        <div className="field col-4 md:col-2">
          <label htmlFor="codigo">Código</label>
          <InputText
            name="codigo"
            disabled
            className="surface-200"
            value={data.person.codigo}
            style={{ textAlign: 'center' }}
          />
        </div>

        <div className="field col-8 md:col-3">
          <label htmlFor="type">
            Tipo
            {data.person.type === EnumTypePerson.JURIDICA &&
              data.person.cpf_cnpj &&
              data.person.cpf_cnpj.length > 0 && (
                <span
                  className="ml-2 p-tag cursor-pointer"
                  onClick={() => handleSearchCnpj(data.person.cpf_cnpj)}
                >
                  <i className="fa-solid fa-search"></i>
                </span>
              )}
          </label>
          <InputDropDown
            name="type"
            options={TipoPessoaOpts}
            value={data.person.type}
            placeholder="Selecionar..."
            onChange={e => {
              formRef.current?.setFieldError('type', '');

              setData({
                ...data,
                person: {
                  ...data.person,
                  type: e.value,
                },
              });
            }}
            disabled={behavior === EnumBehavior.UPDATE}
          />
        </div>

        {behavior === EnumBehavior.UPDATE && (
          <div className="field col-12 md:col-5">
            <label htmlFor="cpf_cnpj">
              {data.person.type === EnumTypePerson.FISICA ? 'CPF' : 'CNPJ'}
            </label>
            <InputText
              name="cpf_cnpj"
              value={data.person.cpf_cnpj}
              placeholder={
                data.person.type === EnumTypePerson.FISICA
                  ? 'Ex.: 000.000.000-00'
                  : 'Ex.: 00.000.000/0000-00'
              }
              disabled
            />
          </div>
        )}

        {behavior === EnumBehavior.CREATE && (
          <div className="field col-12 md:col-5">
            <label htmlFor="cpf_cnpj">
              {data.person.type === EnumTypePerson.FISICA ? 'CPF' : 'CNPJ'}
            </label>
            <InputMask
              name="cpf_cnpj"
              value={data.person.cpf_cnpj}
              mask={
                data.person.type === EnumTypePerson.FISICA
                  ? '999.999.999-99'
                  : '99.999.999/9999-99'
              }
              placeholder={
                data.person.type === EnumTypePerson.FISICA
                  ? 'Ex.: 000.000.000-00'
                  : 'Ex.: 00.000.000/0000-00'
              }
              autoClear={false}
              onComplete={e => {
                if (data.person.type === EnumTypePerson.JURIDICA) {
                  handleSearchCnpj(e.value || '');
                }
              }}
              onChange={e => {
                formRef.current?.setFieldError('cpf_cnpj', '');
                setData({
                  ...data,
                  person: {
                    ...data.person,
                    cpf_cnpj: e.value,
                  },
                });
              }}
              disabled={isLoad}
            />
          </div>
        )}

        <div className="field col-6 md:col-4">
          <label htmlFor="rg_ie">
            {data.person.type === EnumTypePerson.FISICA
              ? 'RG'
              : 'Insc. Estadual'}
          </label>
          {data.person.type === EnumTypePerson.JURIDICA && (
            <a
              className="m-2 p-tag cursor-pointer"
              target="_blank"
              rel="noreferrer"
              href="http://www.sintegra.gov.br/"
            >
              <i className="fa-solid fa-search"></i>
            </a>
          )}
          <InputText
            name="rg_ie"
            placeholder="Ex.: 0000000-000"
            value={data.person.rg_ie}
            onChange={e => {
              formRef.current?.setFieldError('rg_ie', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  rg_ie: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-6 md:col-4">
          <label htmlFor="insc_municipal">Insc. Municipal</label>
          <InputText
            name="insc_municipal"
            placeholder="Ex.: 0000000-00"
            value={data.person.insc_municipal}
            onChange={e => {
              formRef.current?.setFieldError('insc_municipal', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  insc_municipal: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        {data.person.type === EnumTypePerson.FISICA && (
          <>
            <div className="field col-6 md:col-4">
              <label htmlFor="civil_status">Estado Civil</label>
              <InputDropDown
                name="civil_status"
                options={CivilStatusOpts}
                value={data.person.civil_status}
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('civil_status', '');
                  setData({
                    ...data,
                    person: {
                      ...data.person,
                      civil_status: e.value,
                    },
                  });
                }}
              />
            </div>
            <div className="field col-6 md:col-4">
              <label htmlFor="birthday">Aniversário</label>
              <Calendar
                name="birthday"
                dateFormat="dd/mm/yy"
                showIcon
                value={data.person.birthday}
                onChange={e => {
                  if (e.value !== null) {
                    formRef.current?.setFieldError('birthday', '');

                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        birthday: e.value as Date,
                      },
                    });
                  }
                }}
                locale="pt"
              />
            </div>
            <div className="field col-6 md:col-4">
              <label htmlFor="sex">Sexo</label>
              <InputDropDown
                name="sex"
                options={GeneroOpts}
                value={data.person.sex}
                placeholder="Selecionar..."
                onChange={e => {
                  formRef.current?.setFieldError('sex', '');

                  setData({
                    ...data,
                    person: {
                      ...data.person,
                      sex: e.value,
                    },
                  });
                }}
              />
            </div>
          </>
        )}

        <div className="field col-6 md:col-4">
          <label htmlFor="contribuinte">Contribuinte</label>
          <InputDropDown
            name="contribuinte"
            value={Number(data.person.contribuinte)}
            options={ContribuinteOpts}
            placeholder="Selecionar..."
            onChange={e => {
              formRef.current?.setFieldError('contribuinte', '');

              setData({
                ...data,
                person: {
                  ...data.person,
                  contribuinte: e.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-4 flex align-items-end">
          <InputSwitch
            name="block"
            className="mr-3"
            checked={data.person.block}
            trueValue="S"
            falseValue="N"
            onChange={e => {
              formRef.current?.setFieldError('block', '');

              handleChangePersonStatus(e.value.toString());
            }}
          />
          <label htmlFor="block" className="m-0">
            Bloqueado
          </label>
        </div>

        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">Funções</span>
          </div>
        </Divider>

        <div className="field col-6 md:col-4 flex align-items-center">
          <InputSwitch
            name="customer"
            className="mr-3"
            checked={data.person.customer}
            trueValue="S"
            falseValue="N"
            onChange={e => {
              formRef.current?.setFieldError('customer', '');

              setData({
                ...data,
                person: {
                  ...data.person,
                  customer: e.value.toString(),
                },
              });
            }}
          />
          <label htmlFor="customer" className="m-0">
            Cliente
          </label>
        </div>

        <div className="field col-6 md:col-4 flex align-items-center">
          <InputSwitch
            name="provider"
            className="mr-3"
            trueValue="S"
            falseValue="N"
            checked={data.person.provider}
            onChange={e => {
              formRef.current?.setFieldError('provider', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  provider: e.value.toString(),
                },
              });
            }}
          />
          <label htmlFor="provider" className="m-0">
            Fornecedor
          </label>
        </div>

        <div className="field col-12 md:col-4 flex align-items-center">
          <InputSwitch
            name="transporter"
            checked={data.person.transporter}
            className="mr-3"
            trueValue="S"
            falseValue="N"
            onChange={e => {
              formRef.current?.setFieldError('transporter', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  transporter: e.value.toString(),
                },
              });
            }}
          />
          <label htmlFor="transporter" className="m-0">
            Transportador
          </label>
        </div>

        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">Endereço</span>
          </div>
        </Divider>

        <div className="field col-6 md:col-4">
          <label htmlFor="post_code">CEP</label>
          <InputMask
            mask="99.999-999"
            name="post_code"
            placeholder="00.000-000"
            onFocus={e => e.target.select()}
            onComplete={e => loadAddress(e.value || '')}
            value={data.address.post_code}
            onChange={e => {
              formRef.current?.setFieldError('post_code', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  post_code: e.value,
                },
              });
            }}
          />
        </div>
        <div className="field col-6 md:col-4 flex align-items-end">
          <Button
            icon="fa-solid fa-location-dot"
            label="Preencher"
            type="button"
            onClick={() => loadAddress(data.address.post_code)}
          />
        </div>

        <div className="field col-12 md:col-5">
          <label htmlFor="street">Rua/Avenida</label>
          <InputText
            name="street"
            value={data.address.street}
            placeholder="Ex.: Rua da saudade..."
            onChange={e => {
              formRef.current?.setFieldError('street', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  street: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-4 md:col-2">
          <label htmlFor="number">Número</label>
          <InputText
            name="number"
            placeholder="Ex.: S/N"
            value={data.address.number}
            onChange={e => {
              formRef.current?.setFieldError('number', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  number: e.currentTarget.value,
                },
              });
            }}
            style={{ textAlign: 'center' }}
          />
        </div>

        <div className="field col-8 md:col-5">
          <label htmlFor="complement">Complemento | Referência</label>
          <InputText
            name="complement"
            placeholder="Ex.: Qd 99 Lt 99, Prox..."
            value={data.address.complement}
            onChange={e => {
              formRef.current?.setFieldError('complement', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  complement: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-5">
          <label htmlFor="neighborhood">Bairro</label>
          <InputText
            name="neighborhood"
            placeholder="Ex.: Bairro boa vizinhança..."
            value={data.address.neighborhood}
            onChange={e => {
              formRef.current?.setFieldError('neighborhood', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  neighborhood: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-4 md:col-2">
          <label htmlFor="state">UF (Estado)</label>
          <InputDropDown
            name="state"
            placeholder="Selecione..."
            value={data.address.city ? data.address.city.uf : ''}
            onChange={e => {
              formRef.current?.setFieldError('state', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  city: {
                    id: data.address.city?.id || '',
                    ibge: data.address.city?.ibge || '',
                    name: data.address.city?.name || '',
                    uf: e.value,
                  },
                },
              });
            }}
            options={UfsOpts}
            emptyMessage="Não existe UF"
            filter
          />
        </div>

        <div className="field col-8 md:col-5">
          <label htmlFor="city_id">Cidade | Munincipio</label>
          <InputDropDown
            name="city_id"
            value={data.address.city_id}
            options={
              cities && cities.length > 0
                ? cities.filter(c => c.uf === data.address.city?.uf)
                : []
            }
            onChange={e => {
              formRef.current?.setFieldError('city_id', '');
              setData({
                ...data,
                address: {
                  ...data.address,
                  city_id: e.value,
                },
              });
            }}
            placeholder="Selecione..."
            emptyMessage="Não existe Cidades"
            filter
          />
        </div>

        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">Dados complementares</span>
          </div>
        </Divider>

        <div className="field col-6">
          <label htmlFor="regime_tributario">Regime Tributário</label>
          <InputDropDown
            name="regime_tributario"
            value={data.person.regime_tributario}
            options={RegimeTributarioOpts}
            placeholder="Selecionar..."
            onChange={e => {
              formRef.current?.setFieldError('regime_tributario', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  regime_tributario: e.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-6">
          <label htmlFor="insc_suframa">Incrição Suframa</label>
          <InputText
            name="insc_suframa"
            placeholder="Ex.: 000.000.00"
            value={data.person.insc_suframa}
            onChange={e => {
              formRef.current?.setFieldError('insc_suframa', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  insc_suframa: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <Divider align="left">
          <span className="p-tag">Contato</span>
        </Divider>

        <div className="field col-12 md:col-3">
          <label htmlFor="phone_whatsapp">Whatsapp</label>
          <InputMask
            name="phone_whatsapp"
            mask="(99) 99999-9999"
            value={data.contact.phone_whatsapp}
            autoClear={false}
            onChange={e => {
              formRef.current?.setFieldError('phone_whatsapp', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  phone_whatsapp: e.value,
                },
              });
            }}
            placeholder="Ex.: (00) 00000-0000"
          />
        </div>

        <div className="field col-12 md:col-3">
          <label htmlFor="phone_fixed">Telefone Fixo</label>
          <InputMask
            name="phone_fixed"
            mask="(99) 9999-9999"
            autoClear={false}
            value={data.contact.phone_fixed}
            onChange={e => {
              formRef.current?.setFieldError('phone_fixed', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  phone_fixed: e.value,
                },
              });
            }}
            placeholder="Ex.: (00) 0000-0000"
          />
        </div>

        <div className="field col-12 md:col-3">
          <label htmlFor="phone_cel">Telefone Celular 1</label>
          <InputMask
            name="phone_cel"
            mask="(99) 99999-9999"
            autoClear={false}
            value={data.contact.phone_cel}
            onChange={e => {
              formRef.current?.setFieldError('phone_cel', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  phone_cel: e.value,
                },
              });
            }}
            placeholder="Ex.: (00) 00000-0000"
          />
        </div>

        <div className="field col-12 md:col-3">
          <label htmlFor="phone_mobile">Telefone Celular 2</label>
          <InputMask
            name="phone_mobile"
            mask="(99) 99999-9999"
            autoClear={false}
            value={data.contact.phone_mobile}
            onChange={e => {
              formRef.current?.setFieldError('phone_mobile', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  phone_mobile: e.value,
                },
              });
            }}
            placeholder="Ex.: (00) 00000-0000"
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="email">E-mail</label>
          <InputText
            name="email"
            type="email"
            placeholder="Ex.: exemplo@email.com"
            value={data.contact.email}
            onChange={e => {
              formRef.current?.setFieldError('email', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  email: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="site">Site | Loja virtual</label>
          <InputText
            name="site"
            type={'url'}
            placeholder="Ex.: https://sitecliente.com"
            value={data.contact.site}
            onChange={e => {
              formRef.current?.setFieldError('site', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  site: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="facebook">Facebook</label>
          <InputText
            name="facebook"
            type={'url'}
            placeholder="Ex.: https://facebook.com/id"
            value={data.contact.facebook}
            onChange={e => {
              formRef.current?.setFieldError('facebook', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  facebook: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="instagram">Instagram</label>
          <InputText
            name="instagram"
            type={'url'}
            placeholder="Ex.: https://instagram.com/id"
            value={data.contact.instagram}
            onChange={e => {
              formRef.current?.setFieldError('instagram', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  instagram: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="youtube">Youtube</label>
          <InputText
            name="youtube"
            type={'url'}
            placeholder="Ex.: https://youtube.com/id"
            value={data.contact.youtube}
            onChange={e => {
              formRef.current?.setFieldError('youtube', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  youtube: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="twitter">Twitter</label>
          <InputText
            name="twitter"
            type={'url'}
            placeholder="Ex.: https://twitter.com/id"
            value={data.contact.twitter}
            onChange={e => {
              formRef.current?.setFieldError('twitter', '');

              setData({
                ...data,
                contact: {
                  ...data.contact,
                  twitter: e.currentTarget.value,
                },
              });
            }}
          />
        </div>

        <div className="field col-12 md:col">
          <label htmlFor="obs">Observações Adicionais</label>
          <InputTextArea
            name="obs"
            style={{ height: 200 }}
            placeholder="Ex.: Cliente indicado pela Joana indicadora!"
            value={data.person.obs}
            onChange={e => {
              formRef.current?.setFieldError('obs', '');
              setData({
                ...data,
                person: {
                  ...data.person,
                  obs: e.currentTarget.value,
                },
              });
            }}
            maxLength={1024}
          />
        </div>

        <div className="field col-6 md:col-6">
          <Button
            label="Limpar"
            type="reset"
            className="w-full p-button-danger"
            icon="pi pi-trash"
          />
        </div>

        <div className="field col-6 md:col-6">
          <Button
            label={behavior === EnumBehavior.UPDATE ? 'Editar' : 'Salvar'}
            type="submit"
            className="w-full p-button-success"
            icon={
              behavior === EnumBehavior.UPDATE ? 'pi pi-pencil' : 'pi pi-check'
            }
          />
        </div>
      </Form>
    </SideBar>
  );
}
